<template>
  <svg
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29 27H26V5C26 4.46957 25.7893 3.96086 25.4142 3.58579C25.0391 3.21071 24.5304 3 24 3H8C7.46957 3 6.96086 3.21071 6.58579 3.58579C6.21071 3.96086 6 4.46957 6 5V27H3C2.73478 27 2.48043 27.1054 2.29289 27.2929C2.10536 27.4804 2 27.7348 2 28C2 28.2652 2.10536 28.5196 2.29289 28.7071C2.48043 28.8946 2.73478 29 3 29H29C29.2652 29 29.5196 28.8946 29.7071 28.7071C29.8946 28.5196 30 28.2652 30 28C30 27.7348 29.8946 27.4804 29.7071 27.2929C29.5196 27.1054 29.2652 27 29 27ZM8 5H24V27H8V5ZM21 16.5C21 16.7967 20.912 17.0867 20.7472 17.3334C20.5824 17.58 20.3481 17.7723 20.074 17.8858C19.7999 17.9993 19.4983 18.0291 19.2074 17.9712C18.9164 17.9133 18.6491 17.7704 18.4393 17.5607C18.2296 17.3509 18.0867 17.0836 18.0288 16.7926C17.9709 16.5017 18.0007 16.2001 18.1142 15.926C18.2277 15.6519 18.42 15.4176 18.6666 15.2528C18.9133 15.088 19.2033 15 19.5 15C19.8978 15 20.2794 15.158 20.5607 15.4393C20.842 15.7206 21 16.1022 21 16.5Z"
      fill="currentColor"
    />
  </svg>
</template>
