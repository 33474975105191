<template>
  <svg
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 18.9998C21 19.265 20.8946 19.5194 20.7071 19.7069C20.5196 19.8945 20.2652 19.9998 20 19.9998H12C11.7348 19.9998 11.4804 19.8945 11.2929 19.7069C11.1054 19.5194 11 19.265 11 18.9998C11 18.7346 11.1054 18.4802 11.2929 18.2927C11.4804 18.1052 11.7348 17.9998 12 17.9998H20C20.2652 17.9998 20.5196 18.1052 20.7071 18.2927C20.8946 18.4802 21 18.7346 21 18.9998ZM20 13.9998H12C11.7348 13.9998 11.4804 14.1052 11.2929 14.2927C11.1054 14.4802 11 14.7346 11 14.9998C11 15.265 11.1054 15.5194 11.2929 15.7069C11.4804 15.8945 11.7348 15.9998 12 15.9998H20C20.2652 15.9998 20.5196 15.8945 20.7071 15.7069C20.8946 15.5194 21 15.265 21 14.9998C21 14.7346 20.8946 14.4802 20.7071 14.2927C20.5196 14.1052 20.2652 13.9998 20 13.9998ZM27 5.99982V26.9998C27 27.5303 26.7893 28.039 26.4142 28.414C26.0391 28.7891 25.5304 28.9998 25 28.9998H7C6.46957 28.9998 5.96086 28.7891 5.58579 28.414C5.21071 28.039 5 27.5303 5 26.9998V5.99982C5 5.46939 5.21071 4.96068 5.58579 4.58561C5.96086 4.21053 6.46957 3.99982 7 3.99982H11.5325C12.0944 3.37072 12.7828 2.86739 13.5527 2.52277C14.3225 2.17814 15.1565 2 16 2C16.8435 2 17.6775 2.17814 18.4473 2.52277C19.2172 2.86739 19.9056 3.37072 20.4675 3.99982H25C25.5304 3.99982 26.0391 4.21053 26.4142 4.58561C26.7893 4.96068 27 5.46939 27 5.99982ZM12 7.99982H20C20 6.93895 19.5786 5.92154 18.8284 5.17139C18.0783 4.42125 17.0609 3.99982 16 3.99982C14.9391 3.99982 13.9217 4.42125 13.1716 5.17139C12.4214 5.92154 12 6.93895 12 7.99982ZM25 5.99982H21.6562C21.8837 6.64209 22 7.31846 22 7.99982V8.99982C22 9.26504 21.8946 9.51939 21.7071 9.70693C21.5196 9.89446 21.2652 9.99982 21 9.99982H11C10.7348 9.99982 10.4804 9.89446 10.2929 9.70693C10.1054 9.51939 10 9.26504 10 8.99982V7.99982C10 7.31846 10.1163 6.64209 10.3438 5.99982H7V26.9998H25V5.99982Z"
      fill="currentColor"
    />
  </svg>
</template>
