<!-- <template> -->
<!-- <div>
    <div
      class="container-top"
      :class="{ 'container-wide': lgAndUp || xlAndUp }"
    >
      <div class="row"> -->
<!-- Sidebar -->
<!-- <div class="col-md-3 col-12 mt-3 sidebar">
          <div class="row">
            <div class="col-md-12">
              <h2 class="mb-5  text-head text-black font-weight-700">
                Settings
              </h2>
            </div>
          </div>
          <div class="nav-show" @click="toggleSettingDropDown">
            <div>
              <span class="small-text1 dd-list-item"
                >{{ dropDownText }}...</span
              >
              <i
                v-if="settingLinkDropped"
                class="pi pi-angle-up more-icon1 ml-2 pt-2"
                :class="{ 'tbb-icon-rotate': !settingsIcon }"
              ></i>
              <i
                v-if="!settingLinkDropped"
                class="pi pi-angle-down more-icon1 ml-2 pt-2"
                :class="{ 'tbb-icon-rotate': settingsIcon }"
              ></i>
            </div>

            <transition-group name="fade" tag="div">
              <div
                key="show-modal"
                class="show-modal"
                :class="{ 'dd-hide-list': !settingLinkDropped }"
              >
                <div class="row mb-3" v-if="!basicUser">
                  <div class="col-md-12 my-2">
                    <span class="small-text dd-list-ite"
                      >User Management
                    </span>
                  </div>
                  <div
                    class="col-md-12 py-2 my-2 link-con dd-list-item"
                    :class="{ 'active-link': route.path.endsWith('/settings') }"
                  >
                    <router-link
                      class="fw-400 primary--text"
                      to="/tenant/settings"
                      >Users</router-link
                    >
                  </div>
                </div>

                <div class="row mb-3">
                  <div class="col-md-12 my-2">
                    <span class="small-text dd-list-ite"
                      >Organisation profile</span
                    >
                  </div>
                  <div
                    class="col-md-12 py-2 my-2"
                    :class="{
                      'active-link': route.path.endsWith('/settings/profile'),
                    }"
                  >
                    <router-link
                      class="fw-400 primary--text dd-list-item"
                      to="/tenant/settings/profile"
                      >Profile</router-link
                    >
                  </div>
                </div>

                <div class="row mb-3">
                  <div class="col-md-12 my-2">
                    <span class="small-text dd-list-ite">System setting</span>
                  </div>
                  <div
                    class="col-md-12 py-2 my-2"
                    :class="{
                      'active-link': route.path.endsWith(
                        '/settings/defaultmessage'
                      ),
                    }"
                  >
                    <router-link
                      class="fw-400 primary--text dd-list-item"
                      to="/tenant/settings/defaultmessage"
                      >Default message</router-link
                    >
                  </div>
                  <div
                    class="col-md-12 py-2 my-2"
                    :class="{
                      'active-link': route.path.endsWith(
                        '/tenant/settings/membership'
                      ),
                    }"
                  >
                    <router-link
                      class="fw-400 primary--text dd-list-item"
                      to="/tenant/settings/membership"
                      >Membership Category</router-link
                    >
                  </div>
                  <div class="col-md-12 my-2">
                    <router-link
                      class="fw-400 primary--text dd-list-item"
                      to="/tenant/settings/ageGroup"
                      >Age Group Category</router-link
                    >
                  </div>
                  <div class="col-md-12 my-2">
                    <router-link
                      class="fw-400 primary--text dd-list-item"
                      to="/tenant/settings/attendance"
                      >Attendance Category</router-link
                    >
                  </div>
                  <div class="col-md-12 my-2" v-if="false">
                    <router-link
                      class="fw-400 primary--text dd-list-item"
                      to="/tenant/settings/followupstatus"
                      >Follow Up Status</router-link
                    >
                  </div>
                  <div class="col-md-12 my-2">
                    <router-link
                      class="fw-400 primary--text dd-list-item"
                      to="/tenant/settings/firsttimersettings"
                      >New Guest Life Cycle Settings</router-link
                    >
                  </div>
                  <div class="col-md-12 my-2">
                    <router-link
                      class="fw-400 primary--text dd-list-item"
                      to="/tenant/workflow/list"
                      >Workflow</router-link
                    >
                  </div>
                  <div class="col-md-12 my-2">
                    <router-link
                      class="fw-400 primary--text dd-list-item"
                      to="/tenant/settings/archivedpeople"
                      >Archived People</router-link
                    >
                  </div>

                  <div class="col-md-12 my-2">
                    <router-link
                      class="fw-400 primary--text dd-list-item"
                      to="/tenant/settings/branchlevelsettings"
                      >Branch Level Settings</router-link
                    >
                  </div>
                  <div class="col-md-12 my-2">
                    <router-link
                      class="fw-400 primary--text dd-list-item"
                      to="/tenant/settings/customfieldsetup"
                      >Custom Field Setup</router-link
                    >
                  </div>
                  <div class="col-md-12 my-2">
                    <router-link
                      class="fw-400 primary--text dd-list-item"
                      to="/tenant/settings/integrations"
                      >Integrations</router-link
                    >
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-md-12 my-2">
                    <span class="small-text dd-list-ite"
                      >Social & Mobile Setting
                    </span>
                  </div>
                  <div class="col-md-12 my-2">
                    <router-link
                      class="fw-400 primary--text dd-list-item"
                      to="/tenant/settings/social"
                      >Post Category</router-link
                    >
                  </div>
                </div>
                <div class="col-md-12 my-2" v-if="false">
                  <router-link
                    class="fw-400 primary--text"
                    to="/tenant/settings/giving"
                    >Online Giving Setup</router-link
                  >
                </div>
                <div class="row mb-3">
                  <div class="col-md-12 my-2">
                    <span class="small-text dd-list-ite">Danger Zone </span>
                  </div>
                  <div class="col-md-12 my-2">
                    <router-link
                      class="fw-500 text-danger dd-list-ite"
                      to="/tenant/settings/deleteterms"
                      >Delete Account</router-link
                    >
                  </div>
                </div>
                <div class="col-md-12 my-2" v-if="false">
                  <router-link
                    class="fw-400 primary--text"
                    to="/tenant/settings/giving"
                    >Online Giving Setup</router-link
                  >
                </div>
              </div>
            </transition-group>

            <div class="row mb-3" v-if="false">
              <div class="col-md-12 my-2">
                <span class="small-text">Accounting</span>
              </div>
              <div class="col-md-12 my-2">
                <router-link class="font-weight-600 link" to=""
                  >Sales and Currency</router-link
                >
              </div>
            </div>
          </div>
        </div> -->

<!-- main content -->
<!-- <div class="col-md-9 col-12">
          <router-view> </router-view>
        </div> -->
<!-- </div>
    </div>
  </div> -->
<!-- </template> -->
<template>
  <div class="container-top" :class="{ 'container-large': lgAndUp || xlAndUp }">
    <div class="row">
      <div class="col-md-12 mt-3">
        <div class="text-head h2 font-weight-bold py-0 my-0 text-black">
          Settings
        </div>
        <!-- Profile Management Section -->
        <div class="mt-5">
          <div class="mb-3 font-weight-bold text-dak">Profile Management</div>
          <div class="row">
            <SettingCard
              :icon="UsersThreeIcon"
              title="Users"
              route="/tenant/settings/ministryusers"
            />
            <SettingCard
              :icon="UserListIcon"
              title="My Profile"
              route="/tenant/settings/churchinfo"
            />
            <!-- <SettingCard
              :icon="LockKeyOpenIcon"
              title="Reset Password"
              route="/tenant/settings/reset-password"
            /> -->
          </div>
        </div>

        <!-- System Settings Section -->
        <div class="mt-4">
          <h5 class="mb-3 font-weight-bold text-dak">System setting</h5>
          <div class="row">
            <SettingCard
              :icon="ChartCircleDotsIcon"
              title="Default message"
              route="/tenant/settings/defaultmessage"
            />
            <SettingCard
              :icon="GitBranchIcon"
              title="Branch Level Settings"
              route="/tenant/settings/branchlevelsettings"
            />
            <SettingCard
              :icon="ListDashesIcon"
              title="Custom Field Setup"
              route="/tenant/settings/customfieldsetup"
            />
            <SettingCard
              :icon="ArchiveIcon"
              title="Archived People"
              route="/tenant/settings/archivedpeople"
            />
            <SettingCard
              :icon="SimpleWalkIcon"
              title="Attendance Category"
              route="/tenant/settings/attendance"
            />
            <SettingCard
              :icon="ClipBoardTextIcon"
              title="Membership Category"
              route="/tenant/settings/membership"
            />
            <SettingCard
              :icon="BabyCarriageIcon"
              title="Age Group Category"
              route="/tenant/settings/ageGroup"
            />
            <SettingCard
              :icon="MobileCameraIcon"
              title="Integrations"
              route="/tenant/settings/integrations"
            />
            <SettingCard
              :icon="DoorIcon"
              title="New Guest Life Cycle"
              route="/tenant/settings/firsttimersettings"
            />
          </div>
        </div>

        <!-- Social & Mobile Settings Section -->
        <div class="mt-4">
          <h5 class="mb-3 font-weight-bold text-dak">
            Social & Mobile Setting
          </h5>
          <div class="row">
            <SettingCard
              :icon="ThreadLogo"
              title="Post Category"
              route="/tenant/settings/social"
            />
          </div>
        </div>

        <!-- Danger Zone -->
        <div class="mt-5">
          <h5 class="fw-400 s-14">Danger Zone</h5>
          <router-link
            to="/tenant/settings/deleteterms"
            class="text-danger s-14 font-weight-600"
          >
            Delete Account
          </router-link>
        </div>
      </div>
      <!-- <div class="col-md-12">
      <router-view> </router-view>
    </div> -->
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useRoute } from "vue-router";
import SettingCard from "../../components/settings/SettingCard.vue";
import deviceBreakpoint from "../../mixins/deviceBreakpoint";
import BabyCarriageIcon from "../../components/icons/BabyCarriageIcon.vue";
import ChartCircleDotsIcon from "../../components/icons/BabyCarriageIcon.vue";
import DoorIcon from "../../components/icons/DoorIcon.vue";
import GitBranchIcon from "../../components/icons/GitBranchIcon.vue";
import ListDashesIcon from "../../components/icons/ListDashesIcon.vue";
import LockKeyOpenIcon from "../../components/icons/LockKeyOpenIcon.vue";
import MobileCameraIcon from "../../components/icons/MobileCameraIcon.vue";
import SimpleWalkIcon from "../../components/icons/SimpleWalkIcon.vue";
import UserListIcon from "../../components/icons/UserListIcon.vue";
import UsersThreeIcon from "../../components/icons/UsersThreeIcon.vue";
import ThreadLogo from "../../components/icons/ThreadLogo.vue";
import ClipBoardTextIcon from "../../components/icons/ClipBoardTextIcon.vue";
import ArchiveIcon from "../../components/icons/ArchiveIcon.vue";

const route = useRoute();
const { mdAndUp, lgAndUp, xlAndUp, xsOnly } = deviceBreakpoint();

const roleOfCurrentUser = computed(() => {
  if (!localStorage.getItem("roles")) return [];
  return JSON.parse(localStorage.getItem("roles"));
});

const admin = ref(
  roleOfCurrentUser.value.some((i) => i.toLowerCase() === "admin")
);

const basicUser = ref(
  !admin.value &&
    roleOfCurrentUser.value.some((i) => i.toLowerCase() === "basicuser")
);
</script>

<style scoped>
* {
  box-sizing: border-box;
  color: #02172e;
}
.dd-list-item {
  list-style: none;
  text-decoration: none !important;
  color: #0b55d4 !important;
}

.small-text {
  font-size: 14px;
}
.small-text1 {
  display: none;
}

.active-link {
  background: #eff7ff;
  border-radius: 5px;
  width: 100%;
  font-weight: 600 !important;
}
.active-link a {
  text-decoration: none !important;
  font-weight: 600 !important;
}
.fa-angle-up {
  display: none !important;
}
/* .tbb-icon-rotate{
  display: none;
} */

.more-icon1 {
  display: none;
}

@media screen and (min-width: 767px) {
  .sidebar {
    min-height: 100vh;
    border-right: 1px solid #4762f01f;
  }
}

@media screen and (max-width: 1280px) {
  .small-text {
    font-size: 13px;
  }

  .link {
    font-size: 14px;
  }
}
@media screen and (max-width: 767px) {
  .dd-hide-list {
    height: 0;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
    display: none;
  }
  .more-icon1 {
    display: inline-block;
  }

  .dd-list-item {
    list-style: none;
    padding-top: 19px;
    color: #0b55d4 !important;
  }
  .tbb-icon-rotate {
    transition: all 0.5s ease-in-out;
    transform: rotate(180deg);
    color: #190138;
    font-size: 20px;
  }
  .fade-enter-from {
    opacity: 0;
    transform: Scale(0.6);
  }
  .fade-enter-to {
    opacity: 1;
    transform: scale(1);
  }
  .fade-enter-active {
    transition: all 0.2s ease;
  }
  .fade-leave-from {
    opacity: 1;
    transform: translate(0);
  }
  .fade-leave-to {
    opacity: 0;
    transform: translate(-60px);
  }
  .fade-leave-active {
    transition: all 0.3s ease;
  }
  .fa-angle-up {
    display: inline !important;
    margin-left: 60%;
  }
  .small-text1 {
    font-size: 15px !important;
    font-weight: bold;
  }
  .small-text1 {
    display: inline;
  }
}
</style>
