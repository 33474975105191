<template>
  <svg
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22 2H10C9.20435 2 8.44129 2.31607 7.87868 2.87868C7.31607 3.44129 7 4.20435 7 5V27C7 27.7956 7.31607 28.5587 7.87868 29.1213C8.44129 29.6839 9.20435 30 10 30H22C22.7956 30 23.5587 29.6839 24.1213 29.1213C24.6839 28.5587 25 27.7956 25 27V5C25 4.20435 24.6839 3.44129 24.1213 2.87868C23.5587 2.31607 22.7956 2 22 2ZM23 27C23 27.2652 22.8946 27.5196 22.7071 27.7071C22.5196 27.8946 22.2652 28 22 28H10C9.73478 28 9.48043 27.8946 9.29289 27.7071C9.10536 27.5196 9 27.2652 9 27V5C9 4.73478 9.10536 4.48043 9.29289 4.29289C9.48043 4.10536 9.73478 4 10 4H22C22.2652 4 22.5196 4.10536 22.7071 4.29289C22.8946 4.48043 23 4.73478 23 5V27ZM17.5 7.5C17.5 7.79667 17.412 8.08668 17.2472 8.33335C17.0824 8.58003 16.8481 8.77229 16.574 8.88582C16.2999 8.99935 15.9983 9.02906 15.7074 8.97118C15.4164 8.9133 15.1491 8.77044 14.9393 8.56066C14.7296 8.35088 14.5867 8.08361 14.5288 7.79264C14.4709 7.50166 14.5006 7.20006 14.6142 6.92597C14.7277 6.65189 14.92 6.41762 15.1666 6.2528C15.4133 6.08797 15.7033 6 16 6C16.3978 6 16.7794 6.15804 17.0607 6.43934C17.342 6.72064 17.5 7.10218 17.5 7.5Z"
      fill="currentColor"
    />
  </svg>
</template>
