<template>
  <svg
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 4H19C18.4696 4 17.9609 4.21071 17.5858 4.58579C17.2107 4.96086 17 5.46957 17 6V13H6.9C6.66795 11.8714 6.05387 10.8573 5.1613 10.1287C4.26872 9.40003 3.15222 9.00141 2 9C1.73478 9 1.48043 9.10536 1.29289 9.29289C1.10536 9.48043 1 9.73478 1 10C1 10.2652 1.10536 10.5196 1.29289 10.7071C1.48043 10.8946 1.73478 11 2 11C2.79565 11 3.55871 11.3161 4.12132 11.8787C4.68393 12.4413 5 13.2044 5 14C5.00298 16.6513 6.0575 19.1931 7.93222 21.0678C9.80694 22.9425 12.3487 23.997 15 24H20C22.6522 24 25.1957 22.9464 27.0711 21.0711C28.9464 19.1957 30 16.6522 30 14C30 11.3478 28.9464 8.8043 27.0711 6.92893C25.1957 5.05357 22.6522 4 20 4ZM27.935 13H20.8512L26.0837 8.81375C27.0971 9.99802 27.7407 11.4535 27.935 13ZM20 6C21.6419 5.99846 23.2441 6.50543 24.5863 7.45125L19 11.9187V6H20ZM20 22H15C13.0521 21.9976 11.1718 21.2857 9.71082 19.9973C8.24987 18.7089 7.30838 16.9323 7.0625 15H27.9375C27.6916 16.9323 26.7501 18.7089 25.2892 19.9973C23.8282 21.2857 21.9479 21.9976 20 22ZM13 28C13 28.3956 12.8827 28.7822 12.6629 29.1111C12.4432 29.44 12.1308 29.6964 11.7654 29.8478C11.3999 29.9991 10.9978 30.0387 10.6098 29.9616C10.2219 29.8844 9.86549 29.6939 9.58579 29.4142C9.30608 29.1345 9.1156 28.7781 9.03843 28.3902C8.96126 28.0022 9.00087 27.6001 9.15224 27.2346C9.30362 26.8692 9.55996 26.5568 9.88886 26.3371C10.2178 26.1173 10.6044 26 11 26C11.5304 26 12.0391 26.2107 12.4142 26.5858C12.7893 26.9609 13 27.4696 13 28ZM26 28C26 28.3956 25.8827 28.7822 25.6629 29.1111C25.4432 29.44 25.1308 29.6964 24.7654 29.8478C24.3999 29.9991 23.9978 30.0387 23.6098 29.9616C23.2219 29.8844 22.8655 29.6939 22.5858 29.4142C22.3061 29.1345 22.1156 28.7781 22.0384 28.3902C21.9613 28.0022 22.0009 27.6001 22.1522 27.2346C22.3036 26.8692 22.56 26.5568 22.8889 26.3371C23.2178 26.1173 23.6044 26 24 26C24.5304 26 25.0391 26.2107 25.4142 26.5858C25.7893 26.9609 26 27.4696 26 28Z"
      fill="currentColor"
    />
  </svg>
</template>
